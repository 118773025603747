import './App.css';

function App() {
  return (
    <>
      <div className='inner-body'>
        <header className='header'>
          <div className="header-left">
            <img className="logo-img" alt="" src='logo.jpeg'></img>
            <div >
              <p className="page-title">Degombo</p>
            </div>
          </div>

          <div className="header-right">
            <div className='header-item'>
              <p>Products</p>
            </div>
            <div className='header-item'>
              <p>About Us</p>
            </div>
            <div className='header-item'>
              <p>Careers</p>
            </div>
            <div className='header-item'>
              <p>Contact</p>
            </div>

            <button class="hamburger-menu">☰</button>
          </div>
        </header>

        <main>
          <div className='grid'>
            <div>
              <p className="motto">Empowering Ideas,</p>
              <p className="motto">Connecting Creativity</p>
              <p className="content">
                Welcome to our collaborative space! We're a group of friends, each skilled in our own right, coming together in our free time to turn innovative ideas into reality.
                Our journey is about passion, creativity, and the joy of seeing our projects come to life.
              </p>
            </div>

            <div>
              <img className="content-img" alt="" src="logo.jpeg"></img>
            </div>
          </div>

          <div className='grid'>
            <div className="showcase">
              <img className="showcase-img" alt="" src="bela_blok.png"></img>
              <div>
                <p className="showcase-title">Bela Blok</p>
                <p className='showcase-description'>
                  Bela Blok is an intuitive Android app designed for enthusiasts of the card game Belote. Available on Google Play, it simplifies the process of calculating and tracking card points, making the gaming experience more enjoyable and seamless.
                </p>

                <p className='showcase-description'>
                  Since its launch, Bela Blok has garnered a community of approximately 3,000 active users and devices.
                </p>

              </div>

            </div>

            <div className="products-section">
              <p className="motto">Innovations with Impact</p>
              <p className="content">

                Our products are the heart of our collaboration. Developed during weekends and free moments, each product is a testament to our dedication and skill.</p>
              <p className="content">From concept to reality, we focus on creating solutions that benefit the world. Check out what we've crafted!</p>
            </div>

          </div>

          <div className='grid'>
            <div>
              <p className="motto">A Team Driven by Passion</p>
              <p className="content">

                We're not a company, but a circle of friends with a shared vision. Each of us brings unique skills and perspectives, united by the desire to create products that make sense.</p>
              <p className="content">Our story is about balancing our daily lives with our passion for innovation.</p>
            </div>
          </div>

          <div className='grid'>
            <div></div>
            <div className='products-section'>
              <p className="motto">Join Our Creative Journey</p>
              <p className="content">
                While we don't have traditional career paths, we're always open to collaborating with like-minded individuals.
              </p>
              <p className="content">Email: degombo@gmail.com</p>
            </div>
          </div>


          <div className='grid'>
            <div>
              <p className="motto">Contact Us</p>
              <p className="content">
                Have a question or an idea you'd like to share? We're just an email away. Reach out to us at degombo@gmail.com, and let's start a conversation that could lead to something amazing.
              </p>
            </div>
          </div>
        </main>
      </div>

      <footer>
        <img className="showcase-img" alt="" src='logo.jpeg'></img>
        <p className='footer-text'> Vladimira Ruždjaka 8, 10360, Zagreb</p>
        <p className='footer-text'>degombo@gmail.com</p>
        <p className='footer-text'>© 2023 Degombo. All Rights Reserved.</p>
      </footer>
    </>
  );
}

export default App;
